/* TempOwn Default Settings */



@tailwind base;
@tailwind components;
@tailwind utilities;

@import "./main.css";





@layer base {
  :root {
  /*Default  Color */
  --color-primary: #000000;

  --color-primary-100: #e7f8fc;

  --color-secondary: #08a05c;

  --color-green-default: #007660;

  --color-light-green: #89d8c9;

  --color-light-green-100: #effbf9;

  --color-light-green-200: #f0fff4;

  --color-light-green-500: #08a05c;

  --color-info: #00b59c;

  --color-gray: #616564;

  --color-light-gray: #d1d1d1;

  --color-white: #ffffff;

  --color-orange: #fc9c0c;

  --color-disabled: #d1d1d1;
}
  @font-face {
    font-family: Matter;
    src: url("../fonts/matter/Matter-Light.ttf");
    font-weight: 300;
  }

  @font-face {
    font-family: Matter;
    src: url("../fonts/matter/Matter-Regular.ttf");
    font-weight: 400;
  }

  @font-face {
    font-family: Matter;
    src: url("../fonts/matter/Matter-Medium.ttf");
    font-weight: 500;
  }

  @font-face {
    font-family: Matter;
    src: url("../fonts/matter/Matter-SemiBold.ttf");
    font-weight: 600;
  }

  @font-face {
    font-family: Matter;
    src: url("../fonts/matter/Matter-Bold.ttf");
    font-weight: 700;
  }
}

