.bg-gradient {
  background: var(--color-light-green-500)
    linear-gradient(90deg, #266d4d 0%, #08a05c 100%);
}

.bg-gradient-secondary {
  background: var(--color-light-green-500)
    linear-gradient(90deg, #08a05c 0%, #266d4d 101.3%);
}

.bg-danger-gradient {
  background: #f44336 linear-gradient(90deg, #a21308 0%, #f44336 100%);
}

.bg-gradient-gray {
  background: #f9f9f9
    radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #f9f9f9 100%);
  backdrop-filter: blur(4px);
}

.bg-brown {
  background: #ebe4d4;
}

.toggle {
  --width: 40px;
  --height: calc(var(--width) / 2);
  --border-radius: calc(var(--height) / 2);

  display: inline-block;
  cursor: pointer;
}

.toggle__input {
  display: none;
}

.toggle__fill {
  position: relative;
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  background: #dddddd;
  transition: background 0.2s;
}

.toggle__fill::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: var(--height);
  width: var(--height);
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: var(--border-radius);
  transition: transform 0.2s;
}

.toggle__input:checked ~ .toggle__fill {
  background: #009578;
}

.toggle__input:checked ~ .toggle__fill::after {
  transform: translateX(var(--height));
}

.border-thin {
  border: 0.2px solid #89d8c951;
}

.border-thin-top {
  border-top: 0.2px solid #89d8c951;
}

.card .card-header {
  position: relative;
  overflow: hidden;
}

.card .card-header img {
  max-width: 100%;
  transition: all 0.3s;
  display: block;
  width: 100%;
  transform: scale(1);
}

.card:hover .card-header img {
  transform: scale(1.1);
}

.card-body-1 {
  background: linear-gradient(89.44deg, #08a05c -22.94%, #006135 93.22%);
}

.card-body-2 {
  background: linear-gradient(89.44deg, #8e8635 -22.94%, #6f6717 93.22%);
}

.card-body-3 {
  background: linear-gradient(89.44deg, #007660 -22.94%, #02493c 93.22%);
}

.card-body-4 {
  background: linear-gradient(89.44deg, #8da900 -22.94%, #637700 93.22%);
}

/* Activities Status */
.completed-status {
  background: #d9fedf;
  color: #2fb723;
}

.accepted-status {
  background: #f0ffd9;
  color: #6f8e48;
}

.pending-status {
  background: #ffe1b4;
  color: #fc9c0c;
}

.canceled-status {
  background: #ffd3d0;
  color: #f44336;
}

.ongoing-status {
  background: rgba(8, 160, 92, 0.15);
  color: #08a05c;
}

.overdue-status {
  background: rgba(160, 107, 8, 0.15);
  color: #a07008;
}

.unknown-status {
  background: rgba(0, 0, 0, 0.15);
  color: #000;
}

.custom-select::after {
  content: url("../images/svg/arrow-down.svg");
  position: relative;
  padding-left: 1.5rem;
}

.custom-select-list {
  z-index: 1;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.1);
}

.custom-select-list ul {
  max-width: 20rem;
}

.timer-section {
  position: relative;
  background: #effbf9;
}

.home-top-section {
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #f9f9f9 100%);
  backdrop-filter: blur(4px);
}

.home-top-section-right::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(97, 219, 153, 0.05);
  filter: blur(80px);
  border-radius: 100%;
}

.home-top-section-reeddi {
  background: #ffffff;
  border-radius: 20px 20px 0px 0px;
  color: #898989;
}

.search-mini-section {
  width: 100%;
  height: 30vh;
  background-image: url("../images/png/serchbg.png");
  background-repeat: no-repeat;
  background-position: 30% center;
  background-size: cover;
}

.browse-card {
  box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.1);
  border-radius: 100px;
}

.price-tag {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
}

.subscribe-form-input {
  background: #f9f9f9;
  border: 1px solid #d4d5d4;
  box-sizing: border-box;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
}

.camera-bg {
  box-shadow: 10px 5px 40px rgba(0, 0, 0, 0.04);
}

.drone-card {
  background: #89d8c9;
  box-shadow: 10px 5px 40px rgba(0, 0, 0, 0.04);
}

.drone-card-text-smallest {
  color: #2fb723;
  font-size: 9px;
}

/* Testimonial Section custom */
.testimonial-image-bg {
  background-color: #4ce797;
}

.template-slider-image .owl-item img {
  width: 58%;
}

.testimonial-slider .owl-nav.disabled + .owl-dots {
  margin-top: 1.5rem;
  text-align: center;
}

.testimonial-slider .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
  background: #00b59c;
}

.testimonial-slider .owl-dots .owl-dot span {
  background: #9cffac;
}

.testimonial-slider-item {
  background: #f9f9f9;
}

/* Input field custom */
.input-field input:focus {
  border: 1px solid #08a05c;
}

.input-field input:placeholder-shown + .input-label {
  opacity: 0;
  transform: translateY(1rem);
}

.input-field .input-label {
  position: absolute;
  padding: 2px 5px;
  top: -11px;
  left: 18px;
  pointer-events: none;
  background-color: white;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.2s ease-out;
}

/* Radio Custom */
.radio-select {
  position: relative;
  display: inline-flex;
}

.custom-radio::after {
  content: "";
  position: absolute;
  top: 0;
  left: -5px;
  width: 25px;
  height: 25px;
  background-color: white;
  border: 1px solid #d1d1d1;
  border-radius: 50%;
}

.custom-radio:checked::after {
  content: url("../images/svg/check.svg");
  text-align: center;
  background-color: #08a05c;
  position: absolute;
  top: 0;
  left: -5px;
  width: 25px;
  height: 25px;
  border: 1px solid #08a05c;
  border-radius: 50%;
}

.custom-radio-small::after {
  width: 18px;
  height: 18px;
  left: -2px;
  top: -3px;
}

.custom-radio-small:checked::after {
  width: 18px;
  height: 18px;
  left: -2px;
  top: -3px;
  font-size: 0.7rem;
}

.custom-radio + label {
  margin-left: 1.6rem;
}

/* Custom Radio 2 */
.radio-label {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  cursor: pointer;
  position: relative;
}

.radio-label input {
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.radio-label .styling {
  width: 18px;
  height: 18px;
  border: 1px solid #5e605f;
  border-radius: 100%;
  position: relative;
}

.radio-label .styling::before,
.radio-label .styling::after {
  content: "";
  display: block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  position: absolute;
  transform: scale(0);
  transform-origin: center center;
}

.radio-label .styling:before {
  background: #08a05c;
  opacity: 0;
  transition: 0.3s;
}

.radio-label .styling::after {
  background: #08a05c;
  opacity: 0.4;
  transition: 0.6s;
}

/* checked state */
.radio-label input:checked + .styling::before {
  top: -1px;
  left: -0.8px;
  opacity: 1;
  transform: scale(0.7);
}

/* other states */
.radio-label input:hover + .styling,
.radio-label input:focus + .styling {
  border: 1px solid #08a05c;
}

.radio-label input:focus + .styling::after,
.radio-label input:active + .styling::after {
  opacity: 0.1;
  transform: scale(2.6);
}

/* LOADING SPINNER */
.loading-spinner {
  display: inline-block;
  position: relative;
  height: 40px;
  margin-left: -5rem;
  margin-top: -1rem;
}

.loading-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}

.loading-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 3px;
  height: 20px;
  border-radius: 20%;
  background: #08a05c;
}

.loading-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.loading-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.loading-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.loading-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.loading-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.loading-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.loading-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.loading-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.loading-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.loading-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.loading-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.loading-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes lds-spinner {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.main-upload-wrapper {
  background: #effbf9;
  border: 1px dashed #d1d1d1;
  box-sizing: border-box;
  border-radius: 10px;
}

.bg-kyc {
  background: #ffefe4;
}

/* Custom checkbox Styling */
.custom-checkbox {
  display: block;
  position: relative;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 18px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: #fff;
  border: 1px solid #00b59c;
  border-radius: 5px;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: var(--color-secondary);
}

.custom-checkbox input:checked ~ img {
  background-color: var(--color-secondary);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 7.5px;
  top: 4px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.upload_card_wrapper {
  width: 49%;
}

/* Custom Select */
.css-yk16xz-control {
  border-radius: 0.5rem !important;
}

.css-g1d714-ValueContainer {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  height: 3.5rem;
  border-radius: 0.5rem;
}

.custom-input-prefix-wrapper {
  border-radius: 0.5rem;
}

.custom-input-prefix {
  position: relative;
  top: 0;
  left: 0;
  padding-left: 1rem;
  padding-right: 1rem;
  width: 25%;
  height: 96%;
  background-color: var(--color-light-green-200);
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom-input-prefix-field {
  width: 75%;
  border-top-right-radius: 0.5rem;
  border-bottom-right-radius: 0.5rem;
}

.wallet-wrapper {
  width: 32%;
}

.wallet-card {
  background-image: url("../images/svg/abstract4.svg");
  background-repeat: no-repeat;
  background-position: center right;
}

.table-striped tbody tr:nth-of-type(even) {
  background-color: var(--color-light-green-200);
}

/* ----------------------------------- */
/* ------LENDER PAGE------------------ */
/* ---------------------------------- */
.lender-main-wrapper {
  background-image: url("../images/svg/abstract1.svg");
  background-repeat: no-repeat;
}

.hero-emission {
  position: relative;
  background-image: url(../images/svg/emission.svg);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
  border-radius: 10px;
}

.hero-emission::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  background: linear-gradient(93.72deg, #000000 -11.1%, #08a05c 93.73%);
  z-index: -1;
}

.leaderboard-modal-container {
  background: linear-gradient(
    180deg,
    rgba(172, 149, 0, 0.7) 0%,
    rgba(1, 80, 44, 0.7) 100%
  );
  backdrop-filter: blur(16.4671px);
  border-radius: 16.4671px;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left !important;
  margin-top: 1rem;
}

.image-gallery-slides {
  border-radius: 10px;
}

.image-gallery-thumbnail-image {
  border-radius: 10px;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  outline: none;
  border: none !important;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--color-primary);
  margin: -4px 0 0 -4px;
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.image-input {
  position: relative;
  display: inline-block;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100px;
  height: 100px;
}

.image-input.image-input-outline .image-input-wrapper {
  border: 3px solid #ffffff;
  box-shadow: 0 0.5rem 1.5rem 0.5rem rgb(0 0 0 / 8%);
}

.image-input .image-input-wrapper {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  background-repeat: no-repeat;
  background-size: cover;
}

.btn.btn-icon.btn-xs.upload__icon {
  width: 44px;
  height: 44px;
  top: -20px;
}

.btn.btn-white.btn-shadow {
  box-shadow: 0px 9px 16px 0px rgba(24, 28, 50, 0.25) !important;
}

.btn.btn-icon.btn-circle {
  border-radius: 100px;
}

.btn.btn-icon.btn-xs {
  height: 24px;
  width: 24px;
}

.image-input [data-action="change"] input {
  width: 0 !important;
  height: 0 !important;
  overflow: hidden;
  opacity: 0;
}

.btn.btn-icon.btn-xs.upload__icon i {
  font-size: 1.3rem !important;
  color: var(--color-light-green-500) !important;
  right: 0;
  position: absolute;
  bottom: 6px;
  height: 1.6rem;
  width: 1.6rem;
  background: white;
  border-radius: 100px;
  border: 1px solid var(--color-white);
  font-style: normal;
  padding: -10px 0px !important;
}

.how-it-work-card {
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.07);
  border-radius: 15px;
}

.how-it-work-card-image {
  background: #ffffff;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.our-mission-section {
  background-image: url("../images/png/ourMissionBg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}

.react-datepicker-wrapper {
  width: 100%;
}

.hand-section {
  background-image: url("../images/optimised/dd _2_optimised.png") !important;
  background-size: contain;
  background-repeat: no-repeat !important;
  background-position: right !important;
}

.image-gallery-content:not(.fullscreen)
  .image-gallery-slide
  .image-gallery-image {
  max-height: 25rem !important;
  width: 100%;
  object-fit: cover;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 5rem;
}

/* date picker airbnb */
.DateRangePickerInput__withBorder,
.DateInput_input,
.DateInput {
  border-radius: 8px;
  font-family: "Campton", sans-serif !important;
  font-weight: 400;
  font-size: 1rem;
}

.rotate {
  transform: rotate(180deg);
}

.profile-bio-card {
  box-shadow: -10px 40px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.refer-stat-card {
  border: 0.4px solid #00b59c;
  box-sizing: border-box;
  border-radius: 5px;
}

.referral-link-box {
  color: #484c32;
  background-color: #effbf9;
}

/* .discount-price-details {
    position: absolute !important;
    opacity: 0;
  } */

.discount-price-details.show-details {
  position: relative !important;
  opacity: 1;
  animation: slideDown 200ms ease-in;
}

.paystack-button {
  cursor: pointer;
  text-align: center;
  font-size: 10px;
  letter-spacing: 0.1rem;
  text-transform: uppercase;
  background-color: #08a05c;
  font-weight: bold;
  color: white;
  border: none;
  border-radius: 5px;
  width: 100%;
  height: 45px;
  margin-top: 40px;
  text-transform: capitalize;
  font-size: 0.9rem;
  font-family: "Campton", sans-serif !important;
}

.paystack-button:focus {
  outline: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.order-code-box {
  background-color: #fefbb2;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

.item-price-arrow {
  transition: transform ease-in 200ms;
}

.item-price-arrow.rotate-arrow-down {
  transform: rotate(90deg);
}

.box-shadow {
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
}

.st-bottom {
  position: fixed;
  bottom: 0;
  z-index: 2;
}

.pointer {
  cursor: pointer;
}

.animate-close {
  transition: 0.7s cubic-bezier(0.51, -0.12, 0.4, 1.09);
}

.tY-bottom-100 {
  transform: translateY(100%);
}

.tY-top-100 {
  transform: translateY(-100%);
}

.tY-0 {
  transform: translateY(0%);
}

/*! Repeated on purpose for precedence sake vERY iMPORTANT =====*/
.hide-popup-bottom {
  transform: translateY(100%);
}

.hide-popup-top {
  transform: translateY(-100%);
}

/*  CSS transition classes */
.fade-in-enter {
  opacity: 0;
}

.fade-in-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}

.fade-in-exit {
  opacity: 1;
}

.fade-in-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}

.chat-conversation-list {
  border: 0.351464px solid #d1d1d1;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.03);
}

.chat-conversation-body {
  border: 0.318031px solid #08a05c;
}

.chat-header {
  background-color: #5c9f81;
}

.chat-header h4 {
  color: #fff;
}

.chat-header p {
  color: #9cffac;
}

.sender-message {
  background-color: #fff7e5;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
}

.reciever-message {
  background-color: #effbf9;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  border-top-right-radius: 15px;
}

.item-insured-pin {
  color: #08a05c;
  background-color: #d2f8e4;
  border: 2px solid #34f892;
  border-right: none;
  z-index: 10;
}

.menu-link-item {
  transition: all ease-in-out 200ms;
}

.menu-link-item:hover {
  background-color: #effbf9;
}

.one-liner {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  /* number of lines to show */
  -webkit-box-orient: vertical;
}

.modal-min-height {
  min-height: 540px;
}

/* Calendat styling */
.rmdp-wrapper {
  border-radius: 20px !important;
}

.rmdp-wrapper.rmdp-shadow {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.3);
}

.rmdp-calendar {
  border-radius: 20px !important;
  width: 100% !important;
  padding: 1rem 1.2rem 2rem !important;
}

.rmdp-header-values {
  text-transform: uppercase !important;
  font-size: 0.8rem !important;
  color: #616564 !important;
  font-weight: 600;
}

.rmdp-day-picker > div {
  width: 100% !important;
}

.rmdp-week-day {
  font-weight: 500 !important;
  color: #616564 !important;
  font-size: 0.75rem;
}

.rmdp-day {
  border-radius: 100% !important;
  margin-bottom: 0.3rem !important;
}

.rmdp-day:not(.rmdp-disabled):not(.rmdp-day-hidden):hover span {
  color: #616564 !important;
  background-color: #f0fff4 !important;
}

.rmdp-day span {
  font-size: 0.75rem !important;
}

.rmdp-day,
.rmdp-week-day {
  height: 2.9rem !important;
  width: 2.9rem !important;
}

.rmdp-week {
  width: 100% !important;
  justify-content: space-evenly !important;
}

.rmdp-day.rmdp-selected {
  border-radius: 100% !important;
}

.rmdp-day.rmdp-selected span:not(.highlight),
.rmdp-range {
  box-shadow: none !important;
  background-color: #bbb !important;
  color: #616564 !important;
}

.rmdp-day.rmdp-today span {
  color: #000 !important;
  background: transparent !important;
}

.cstm-min-w-20 {
  min-width: 2.5rem;
}

.rmdp-day.start,
.rmdp-day.end {
  background-color: #00b59c !important;
  font-weight: bold;
}

.rmdp-day.start span,
.rmdp-day.end span {
  color: white !important;
}

.rmdp-day.rmdp-range:not(.start):not(.end) {
  border-radius: 0px !important;
  background-color: #f0fff4 !important;
}

.rmdp-arrow-container:hover {
  padding: 2.1px 3.5px 4.9px 5px;
  background-color: var(--color-light-green-500);
}

.rmdp-left:hover {
  padding: 2px 5.5px 0px 5px;
}

.rmdp-arrow {
  border: solid var(--color-light-green-500);
  border-width: 0 2px 2px 0;
}

.rmdp-wrapper .rmdp-action-buttons .rmdp-button.rmdp-action-button:first-child {
  color: var(--color-secondary);
}

.rmdp-wrapper .rmdp-action-buttons .rmdp-button.rmdp-action-button:last-child {
  color: #d91a0c;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
  /* Safari and Chrome */
}

.hide-scroll {
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
}

/*! ==========================================================*/

@media (max-width: 987px) {
  .hand-section {
    background-image: none;
  }

  .rmdp-day,
  .rmdp-week-day {
    height: 2.5rem !important;
    width: 2.5rem !important;
  }

  .rmdp-calendar {
    width: 100% !important;
    padding: 1rem 1rem !important;
  }

  .rmdp-header-values {
    font-size: 0.6rem !important;
  }
}

@media (max-width: 1024px) {
  .search-section::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .wallet-wrapper {
    width: 49%;
  }
}

@media (min-width: 768px) {
  .wallet-wrapper {
    min-width: 300px;
  }
}

@media (max-width: 640px) {
  .search-section {
    height: 50vh;
  }

  .upload_card_wrapper {
    width: 100%;
  }

  .wallet-wrapper {
    width: 100%;
  }
}

.rotate-arrow {
  transition: all ease-in-out 200ms;
}

.rotate-arrow-up {
  transform: rotate(180deg);
}

.rotate-arrow-down {
  transform: rotate(0deg);
}

.serial-number {
  border: 1px solid #d1d1d1;
}

.serial-number.selected {
  background: #effbf9;
  border: 1px solid #08a05c;
}

.user-profile-modal-popup {
  box-shadow: 0px 3.39468px 42.4335px rgba(0, 0, 0, 0.1);
  transition: all ease-in-out 200ms;
}

.user-profile-modal-popup::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  right: 3rem;
  width: 0;
  height: 0;
  border-top: 20px solid white;
  border-left: 20px solid transparent;
}

@media screen and (max-width: 1020px) {
  .user-profile-modal-popup .upload-image-user p {
    font-size: 0.65rem;
  }

  .user-profile-modal-popup .upload-image-user-action-button {
    font-size: 0.6rem;
  }
}

.auth-left-container {
  /* background: linear-gradient(180deg, #08a05c 0%, #266d4d 100%); */
}

.auth-bg-gradient {
  background: linear-gradient(180deg, #08a05c 0%, #266d4d 100%);
}

.auth-left-image {
  width: 100%;
  height: calc(100vh - 15rem);
  object-fit: cover;
}

.auth-left-card-container {
  background: rgba(8, 160, 92, 0.4);
  border: 0.805778px solid #47ca5c;
  box-sizing: border-box;
  box-shadow: 0px 3.22311px 40.2889px rgba(0, 0, 0, 0.1);
}

.otp-phone-number {
  background: #effbf9;
  color: #484c32;
}

.bg-green-gradient {
  background: linear-gradient(
    171.46deg,
    #08a05c 6.53%,
    #18992d 36.29%,
    #007d45 70.03%
  );
}

.verify-option-card {
  border: 0.7px solid #e1e1e1;
  box-sizing: border-box;
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.04);
}

.verify-option-card
  .verify-option-card-option
  .verify-option-card-option-arrow {
  transform: translateX(0px);
  transition: all ease-in-out 200ms;
}

.verify-option-card
  .verify-option-card-option:hover
  .verify-option-card-option-arrow {
  transform: translateX(5px);
}

.locked-status {
  background: #fff1cf;
  border-radius: 5px;
  color: #e0a008;
  padding: 0.3rem 0.4rem 0.2rem;
}

.late-rental-footer {
  box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.25);
}

.coming-soon-tip {
  color: #08a05c;
  background: #c4ffe4;
}

.menu-line {
  border: 0.3px solid #c2c2c2;
}

footer {
  background: #f9f9f9;
}

.footer-b-border {
  border-bottom: 0.3px solid #656565;
}

@media screen and (max-width: 768px) {
  .footer-b-border {
    border-bottom: none;
  }
}

.get-update-section .container {
  background: linear-gradient(90.2deg, #effbf9 0.18%, #f6f6f6 99.83%);
}

.rental-activity-header {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(3px);
  background: #ffffff;
}

.rental-activity-tab.active::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 35%;
  margin: auto;
  width: 20%;
  height: 2px;
  display: flex;
  justify-content: center;
  background-color: var(--color-secondary);
}

.category-icon-card,
.rental-activity-card {
  position: relative;
  transition: all ease-in-out 200ms;
}

.category-icon-card:hover {
  transform: scale(1.03);
  filter: grayscale(100);
}

.browse-catergory-list .owl-item:first-child .category-icon-card {
  background-color: #f1f3f3;
}
.browse-catergory-list .owl-item:nth-child(2) .category-icon-card {
  background-color: #edffff;
}
.browse-catergory-list .owl-item:nth-child(3) .category-icon-card {
  background-color: #f3fced;
}
.browse-catergory-list .owl-item:nth-child(4) .category-icon-card {
  background-color: #fff3f0;
}

.browse-catergory-list .owl-item:last-child .category-icon-card {
  background-color: #f1f7ff;
}

.rental-activity-card:hover {
  transform: scale(1.03);
}

.category-modal-lg {
  box-shadow: 0px 4px 80px rgba(0, 0, 0, 0.5);
}

.category-more {
  background: #f0fff8;
}

.search-location-result-header {
  background: url("../images/png/location/location.webp") no-repeat;
  background-size: cover;
  background-position: center;
}

.verifyProfile-tooltip {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
  color: #616564 !important;
  background-color: #effbf9 !important;
}

.verifyProfile-tooltip.place-top:after {
  border-top-color: #effbf9 !important;
  border-top-style: solid !important;
  border-top-width: 6px !important;
}

.upload-item-quantity {
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.04);
}

.upload-item-tip {
  background: #f7f8f7;
}

.equipment-seo-section {
  background: #f9f9f9;
}

.check-availability-form-section {
  background: #f9f9f9;
  backdrop-filter: blur(3px);
}

.discount-card {
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.discount-2 {
  background: linear-gradient(89.9deg, #f4d66a -131.88%, #657540 114.16%);
}

.discount-3 {
  background: linear-gradient(89.9deg, #6f8e48 -131.88%, #47ca5c 114.16%);
}

.checkout-card {
  border: 0.5px solid #9a9a9a;
}

.rental-request-modal-daterange {
  background: linear-gradient(90deg, #978f42 0%, #08a05c 112.32%);
}

.ads-banner {
  background: url("../images/svg/tempown.svg"), #fdf3e3;
}

.about-bottom-section {
  width: 100%;
  background: url("../images/svg/about/leaf.svg") no-repeat,
    url("../images/png/about/about-bottom.webp") no-repeat;
  background-position: top center, bottom center;
  background-size: 48%, 100%;
}

.emission-top-section {
  width: 100%;
  background: url("../images/png/emission/leaf.svg") no-repeat,
    url("../images/png/emission/emission.webp") no-repeat;
  background-position: top 5rem right 20rem, bottom center;
  background-size: auto, 100%;
}

@media screen and (max-width: 987px) {
  .about-bottom-section {
    background: url("../images/svg/about/leaf.svg") no-repeat,
      url("../images/png/about/about-bottom-sm.webp") no-repeat;
    background-position: top 2.5rem center, bottom center;
    background-size: 90%, 100%;
  }

  .emission-top-section {
    width: 100%;
    background: url("../images/png/emission/leaf.svg") no-repeat,
      url("../images/png/emission/emission.webp") no-repeat;
    background-position: top 1rem right 7rem, bottom center;
    background-size: 7%, 100%;
  }
}

.faq-banner {
  width: 100%;
  min-height: 25rem;
  border-radius: 20px;
  background: url("../images/png/faq/faqBanner.webp") no-repeat,
    var(--color-secondary);
  background-size: cover;
  background-position: center;
}

.faq-card img {
  transition: all ease-in-out 300ms;
}

.faq-card:is(:hover, :focus) img {
  filter: grayscale(100%);
}

.policy-active {
  position: relative;
}

.policy-active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 25px;
  height: 2px;
  background: #08a05c;
}

.text-yellow {
  color: #ffc439;
}
